import styled from "styled-components";
import {Link} from "gatsby";

const LinkListWrapper = styled.ol`
	display: flex;
	flex-direction: column;
`;

const StyledLink = styled(Link)`
	text-decoration: none;
	color: black;
	
	:hover  {
		color: grey;
	}
`;

export {
	StyledLink,
	LinkListWrapper
}
