import React from "react"
import SEO from "../../components/seo"
import Layout from "../../components/layout"
import {LinkListWrapper, StyledLink} from "../../components/link";


const AboutUsPage = () => {
	return (
		<Layout>
			<SEO title={"About us"}/>
			<h1 className={"title"}>About Us</h1>
			<LinkListWrapper>
				<li>
					<StyledLink to={"/about-us/history"} key={'history'}>
						History
					</StyledLink>
				</li>
				<li>
					<StyledLink to={"/about-us/downloads"} key={'downloads'}>
						Downloads
					</StyledLink>
				</li>
			</LinkListWrapper>

		</Layout>
	)
}

export default AboutUsPage
